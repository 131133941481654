import { tr } from 'mmfintech-commons'

import './productForm.scss'

import { CoreInput, CustomCheckbox, CoreSelect } from '@components'

import CompanyIcon from '@images/icons/company-register.svg?react'

export const ProductForm = ({ productFormValues, isIndividualSelected }) => {
  const { getValue, setValue, registerInput, getError } = productFormValues

  const toggleValue = (key: string): void => setValue(key, !getValue(key))

  return (
    <form className='product-form-wrapper'>
      <div className='products'>
        <CustomCheckbox
          caption={tr('FRONTEND.SIGNUP.PRODUCT_FORM.BANKING_SERVICES', 'Banking Services')}
          checked={getValue('bankingServices')}
          toggle={() => toggleValue('bankingServices')}
          dataTest='banking-services'
          icon={<CompanyIcon />}
        />

        <CustomCheckbox
          caption={tr('FRONTEND.SIGNUP.PRODUCT_FORM.PAYMENT_SERVICES', 'Payment Services')}
          checked={getValue('paymentServices')}
          toggle={() => toggleValue('paymentServices')}
          dataTest='payment-services'
          icon={<CompanyIcon />}
        />

        <CustomCheckbox
          caption={tr('FRONTEND.SIGNUP.PRODUCT_FORM.FOREX', 'Forex Trading')}
          checked={getValue('forex')}
          toggle={() => toggleValue('forex')}
          dataTest='forex-trading'
          icon={<CompanyIcon />}
        />

        <CustomCheckbox
          caption={tr('FRONTEND.SIGNUP.PRODUCT_FORM.OTHER', 'Other')}
          checked={getValue('other')}
          toggle={() => toggleValue('other')}
          dataTest='other'
          optional={
            <CoreInput
              type='text'
              data-test={'other-value'}
              value={getValue('otherValue')}
              onChange={(_, value) => {
                setValue('otherValue', value)
              }}
              label={tr('FRONTEND.SIGNUP.PRODUCT_FORM.OTHER_DESCRIPTION', 'Please specify (optional)')}
              error={getError('otherValue')}
            />
          }
          icon={<CompanyIcon />}
        />
      </div>

      <div className='selects-section'>
        <CoreSelect
          // type='select'
          data-test='heard-about-us'
          label={tr('FRONTEND.SIGNUP.HEARD_ABOUT_US', 'Where did you heard about us?')}
          options={[
            { value: 'Internet Search', label: tr('FRONTEND.SIGNUP.HEARD_FROM_INTERNET_SEARCH', 'Internet Search') },
            { value: 'Friend', label: tr('FRONTEND.SIGNUP.HEARD_FROM_FRIEND', 'Friend') },
            { value: 'Social Media', label: tr('FRONTEND.SIGNUP.HEARD_FROM_SOCIAL_MEDIA', 'Social Media') },
            { value: 'OffshoreCorpTalk', label: tr('FRONTEND.SIGNUP.HEARD_FROM_CORP_TALK', 'OffshoreCorpTalk') },
            { value: 'Jeton', label: tr('FRONTEND.SIGNUP.HEARD_FROM_JETON', 'Jeton') },
            { value: 'Other', label: tr('FRONTEND.SIGNUP.HEARD_FROM_OTHER', 'Other') }
          ]}
          {...registerInput('heardAboutUs')}
        />

        {isIndividualSelected && (
          <CoreSelect
            data-test='expected-deposit-volume'
            label={tr('FRONTEND.SIGNUP.EXPECTED_DEPOSIT_VOLUME', 'Expected deposit volume')}
            options={[
              { value: '10.000-20.000 EUR', label: '10.000-20.000 EUR' },
              { value: '20.000-50.000 EUR', label: '20.000-50.000 EUR' },
              { value: '50.000-100.000 EUR', label: '50.000-100.000 EUR' },
              { value: '100.000-500.00 EUR', label: '100.000-500.00 EUR' },
              { value: '500.000-1M EUR', label: '500.000-1M EUR' },
              { value: 'More Than 1M EUR', label: 'More Than 1M EUR' }
            ]}
            {...registerInput('expectedDepositVolume')}
            required
          />
        )}
      </div>
    </form>
  )
}
