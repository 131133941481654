import { useContext, useEffect } from 'react'

import {
  api,
  selectCurrentUserRole,
  useAppSelector,
  useDeleteAccountMutation,
  useGetProfileQuery,
  useLazyCardLogoutQuery,
  useLazyLogoutQuery
} from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, tr } from 'mmfintech-commons'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import { setCurrentStatus } from './SecurityConfiguration'
import ChangeEmail from './elements/ChangeEmailModal'
import ChangeMobileNumber from './elements/ChangeMobileModal'
import ChangePassword from './elements/ChangePasswordModal'
import Change2FA from './elements/ChangeTwoFactorModal'
import './styled/profile.scss'

import { CoreButton } from '@components'

import { DeleteAccountConfirmModal } from './elements/DeleteAccountConfirmModal'
import { DeleteAccountErrorModal } from './elements/DeleteAccountErrorModal'
import { useAppDispatch } from '@hooks'

import EditIcon from '@images/icons/edit-setting.svg?react'
import LockIcon from '@images/icons/change-lock-icon.svg?react'
import TrashIcon from '@images/icons/trash-icon.svg?react'
import { CustomerRoleEnum } from 'mmfintech-commons-types'
import Change3dsPhoneNumber from './elements/Change3dsPhoneModal'

import InformationIcon from '@images/icons/information_symbol_icon.svg?react'
import ReactTooltip from 'react-tooltip'

interface SecuritySettingsProps {
  twoFactorPhone?: string
  phone3ds?: string
  handleOpenModal?: (key: string) => void
  customerRole?: CustomerRoleEnum
}

const items = [
  {
    title: tr('FRONTEND.SETTINGS.OPTIONS.EMAIL', 'Email'),
    key: 'email'
  },
  {
    title: tr('FRONTEND.SETTINGS.OPTIONS.2FA', 'Two-factor authentication method'),
    key: 'twoFactorAuth'
  },
  {
    title: tr('FRONTEND.SETTINGS.OPTIONS.SET_PHONE', 'Set phone number'),
    key: 'setPhoneNumber',
    tooltip: tr(
      'FRONTEND.SETTINGS.OPTIONS.2FA_TOOLTIP',
      "Once you set the two-factor authentication method to SMS, you'll receive 2FA codes on that phone number to verify your identity."
    )
  },
  {
    title: tr('FRONTEND.SETTINGS.OPTIONS.PHONE_NUMBER', '2FA phone number'),
    key: 'phoneNumber',
    tooltip: tr(
      'FRONTEND.SETTINGS.OPTIONS.2FA_TOOLTIP',
      "Once you set the two-factor authentication method to SMS, you'll receive 2FA codes on that phone number to verify your identity."
    )
  },
  {
    title: tr('FRONTEND.SETTINGS.OPTIONS.3DS_PHONE', '3DS phone number'),
    key: '3dsPhone',
    tooltip: tr(
      'FRONTEND.SETTINGS.OPTIONS.3DS_TOOLTIP',
      "To enhance security and prevent fraud in your transactions, we'll send a 3DS verification code to the phone number provided."
    )
  }
]

export const SecuritySettings = ({
  twoFactorPhone = '',
  handleOpenModal,
  customerRole,
  phone3ds = ''
}: SecuritySettingsProps) => {
  return (
    <div className='preview-container'>
      <div className='preview-security'>
        {items.map((item, index) => {
          const { key, title, tooltip } = item

          const status = setCurrentStatus(key)

          if (
            (!phone3ds && key === '3dsPhone') ||
            (twoFactorPhone && key === 'setPhoneNumber') ||
            (!twoFactorPhone && key === 'phoneNumber') ||
            (customerRole === CustomerRoleEnum.STAFF && key === 'email')
          ) {
            return null
          }

          return (
            <div
              data-test={`security-settings-icon-${title}`}
              key={index}
              className={`preview-security-content-wrapper`}>
              <div className='preview-content'>
                <span data-test={`security-settings-title-${title}`}>
                  <div className='preview-security-content-title'>
                    {title}
                    {tooltip && (
                      <div className='information-icon' data-for='setting-information' data-tip={tooltip}>
                        <InformationIcon />
                      </div>
                    )}
                  </div>
                </span>
                <p data-test={`security-settings-status-${title}`}>{status}</p>
              </div>
              <CoreButton
                className='edit-setting'
                variation='elevated'
                LeftIcon={<EditIcon />}
                text={tr('FRONTEND.BUTTONS.EDIT', 'Edit')}
                onClick={() => handleOpenModal(key)}
              />
            </div>
          )
        })}
        <ReactTooltip
          className='setting-tooltip'
          id='setting-information'
          type='dark'
          effect='solid'
          place='bottom'
          multiline={true}
          delayShow={200}
        />
      </div>
    </div>
  )
}

function Security() {
  const [deleteAccount, { error: deleteAccountError }] = useDeleteAccountMutation()
  const { otpOnSuccess, setOtpOnSuccess } = useContext(OtpContext)
  const customerRole = useAppSelector(selectCurrentUserRole)
  const { modalShow } = useContext(GlobalContext)
  const [logout] = useLazyLogoutQuery()
  const [cardLogout] = useLazyCardLogoutQuery()
  const dispatch = useAppDispatch()

  const { data: profile, isLoading, error, refetch } = useGetProfileQuery(null)
  const { twoFactorPhone, phone3ds } = profile || {}

  const handleDeleteAccountClick = () => {
    setOtpOnSuccess(() => async () => {
      await cardLogout(null).unwrap()
      await logout(null).unwrap()
      dispatch(api.util.resetApiState())
    })
    modalShow({
      options: { closeOnClickOutside: false },
      header: tr('FRONTEND.SETTINGS.MODAL.DELETE_PROFILE.TITLE', 'Delete profile'),
      content: <DeleteAccountConfirmModal handleSubmit={() => deleteAccount(null)} />
    })
  }

  useEffect(() => {
    if (deleteAccountError) {
      modalShow({
        options: { closeOnClickOutside: false },
        header: tr('FRONTEND.SETTINGS.MODAL.DELETE_PROFILE.ERROR', 'Delete profile error'),
        content: <DeleteAccountErrorModal error={deleteAccountError} />
      })
    }
  }, [deleteAccountError])

  useEffect(() => {
    refetch()
  }, [otpOnSuccess])

  const handleOpenModal = (key: string) => {
    switch (key) {
      case 'email':
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.SETTINGS.MODAL.CHANGE_EMAIL.TITLE', 'Change email'),
          content: <ChangeEmail />
        })
        break
      case 'twoFactorAuth':
        modalShow({
          options: { size: 'large' },
          header: tr('FRONTEND.SETTINGS.MODAL.2FA.TITLE', 'Two-factor authentication method'),
          content: <Change2FA />
        })
        break
      case 'setPhoneNumber':
        modalShow({
          options: { size: 'large' },
          header: tr('FRONTEND.SETTINGS.MODAL.SET_PHONE.TITLE', 'Set phone number'),
          content: <ChangeMobileNumber />
        })
        break
      case 'phoneNumber':
        modalShow({
          options: { size: 'large' },
          header: tr('FRONTEND.SETTINGS.MODAL.CHANGE_PHONE.TITLE', 'Change phone number'),
          content: <ChangeMobileNumber />
        })
        break
      case '3dsPhone':
        modalShow({
          options: { size: 'large' },
          header: tr('FRONTEND.SETTINGS.MODAL.3DS_PHONE.TITLE', 'Change 3DS phone number'),
          content: <Change3dsPhoneNumber />
        })
        break
      case 'changePassword':
        modalShow({
          options: { closeOnClickOutside: false },
          header: tr('FRONTEND.SETTINGS.MODAL.CHANGE_PASSWORD.TITLE', 'Change password'),
          content: <ChangePassword />
        })
        break
      case 'deleteProfile':
        handleDeleteAccountClick()
        break

      default:
      //nothing
    }
  }

  return (
    <div style={{ flexDirection: 'row' }} className='security-settings-container'>
      <div className='security-wrapper'>
        <p className='security-title'>{tr('FRONTEND.PROFILE.SECURITY_DETAILS_TITLE', 'Security details')}</p>
        <ErrorDisplay error={error} />
        {!isLoading ? (
          profile && (
            <SecuritySettings
              twoFactorPhone={twoFactorPhone}
              handleOpenModal={handleOpenModal}
              customerRole={customerRole}
              phone3ds={phone3ds}
            />
          )
        ) : (
          <Preloader />
        )}

        <div className='preview-container'>
          <div className='preview-security'>
            <div data-test={`security-settings-icon-change-password`} className={`preview-security-action-wrapper`}>
              <div className='preview-content'>
                <span data-test={`security-settings-title-change-password`}>
                  {tr('FRONTEND.SETTINGS.OPTIONS.CHANGE_PASSWORD', 'Change password')}
                </span>
              </div>
              <CoreButton
                className='action-button'
                variation='primary'
                size='normal'
                LeftIcon={<LockIcon />}
                text={tr('FRONTEND.BUTTONS.CHANGE', 'Change')}
                onClick={() => handleOpenModal('changePassword')}
              />
            </div>
            {customerRole == CustomerRoleEnum.OWNER && (
              <>
                <div className='separator' />
                <div data-test={`security-settings-icon-delete-profile`} className={`preview-security-action-wrapper`}>
                  <div className='preview-content'>
                    <span data-test={`security-settings-title-delete-profile`}>
                      {tr('FRONTEND.SETTINGS.OPTIONS.DELETE_PROFILE', 'Delete profile')}
                    </span>
                  </div>
                  <CoreButton
                    className='action-button'
                    variation='primary'
                    size='normal'
                    LeftIcon={<TrashIcon />}
                    text={tr('FRONTEND.BUTTONS.DELETE', 'Delete')}
                    onClick={() => handleOpenModal('deleteProfile')}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Security
