import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

import parse from 'html-react-parser'

import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import { paths, useCountries, usePublicCurrencies, useSignupMutation } from 'mmfintech-backend-api'
import { findCurrenciesByCountry, isEmptyString, isValidFunction, tr, useFormValues } from 'mmfintech-commons'

import { CoreInput } from '../CoreInput'
import { CoreSteps } from '@components'
import { CoreButton } from '../CoreButton'
import { CoreSelect } from '../CoreSelect'
import { ProductForm } from './ProductForm'
import { CoreCheckbox } from '../CoreCheckbox'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { PasswordTerms } from '../PasswordTerms'
import { CustomCheckbox } from '../CustomCheckbox'

import { CountrySupportedPurposeEnum, Currency, CurrencyTypeEnum } from 'mmfintech-commons-types'

import './registerForm.scss'

import ArrowIcon from '@images/icons/red-right-arrow.svg?react'
import CompanyIcon from '@images/icons/company-register.svg?react'
import IndividualIcon from '@images/icons/individual-register.svg?react'

interface RegisterFormProps {
  referralCode?: any
  campaign?: string
}

const stepTitle = {
  1: tr('FRONTEND.SIGNUP.STEP_TITLE_1', 'About yourself'),
  2: tr('FRONTEND.SIGNUP.STEP_TITLE_2', 'Select product')
}

const stepSubtitle = {
  1: tr('FRONTEND.SIGNUP.STEP_SUBTITLE_1', 'Tell us about yourself!'),
  2: tr('FRONTEND.SIGNUP.STEP_SUBTITLE_2', 'Select product')
}

export const RegisterForm = ({ referralCode, campaign }: RegisterFormProps) => {
  const [signup, { error: signUpError, isLoading: signUpFetching, reset: resetSignup }] = useSignupMutation()
  const [passwordFocused, setPasswordFocused] = useState<boolean>(false)
  const isMobile = useMatchMedia({ breakpoint: breakpoints.sm, prefix: 'max' })

  const { currencies } = usePublicCurrencies()
  const { countryOptions, countriesError } = useCountries(CountrySupportedPurposeEnum.REGISTRATION)

  const formValues = useFormValues({
    countryCode: { required: true },
    email: { required: true, validation: 'email' },
    password: { required: true, validation: 'password' },
    entityType: { required: true },
    currency: {},
    contactPhone: { validation: 'phone', required: true },
    name: { required: true, validation: 'name' }
  })

  const { getValue, registerInput } = formValues

  const isIndividualSelected = getValue('entityType') === 'INDIVIDUAL'

  const productFormValues = useFormValues({
    bankingServices: { value: false },
    paymentServices: { value: false },
    forex: { value: false },
    other: { value: false },
    otherValue: { validation: 'safe-string' },
    heardAboutUs: { required: true },
    expectedDepositVolume: { required: isIndividualSelected }
  })

  const { getValue: getValueProduct } = productFormValues

  const history = useHistory()

  const [step, setStep] = useState(1)
  const [agree, setAgree] = useState(false)
  const [country, setCountry] = useState(null)

  const handleContinueClick = () => {
    if (formValues.areValid()) {
      setStep(2)
    }
  }

  const handleCountryChange = (_: any, value: any) => {
    formValues.setValue('countryCode', value)
    setCountry(value)
  }

  useEffect(() => {
    if (country) {
      const extractedCurrency = findCurrenciesByCountry(country)[0]
      const found = currencies.find(
        (c: Currency) =>
          c.supportedForPrimary && c.type === CurrencyTypeEnum.FIAT && c.currencyCode === extractedCurrency
      )
      if (found) {
        formValues.setValue('currency', found.currencyCode)
      }
    }
    // eslint-disable-next-line
  }, [country])

  useEffect(() => {
    return () => {
      resetSignup()
    }
  }, [])

  useEffect(() => {
    if (countryOptions?.length === 1 && isEmptyString(formValues.getValue('countryCode'))) {
      formValues.setValue('countryCode', countryOptions[0]?.value)
    }
    // eslint-disable-next-line
  }, [countryOptions])

  useEffect(() => {
    formValues.handleErrors(signUpError)
    // eslint-disable-next-line
  }, [signUpError])

  useEffect(() => {
    return () => {
      setStep(1)
    }
  }, [])

  const handleSignupSubmit = async () => {
    if (formValues.areValid() && productFormValues.areValid()) {
      const data = {
        ...(referralCode ? { referralCode } : {}),
        languageCode: 'en',
        ...formValues.prepare(),
        products: {
          bankingServices: productFormValues.getValue('bankingServices'),
          paymentServices: productFormValues.getValue('paymentServices'),
          forex: productFormValues.getValue('forex'),
          heardAboutUs: productFormValues.getValue('heardAboutUs'),
          ...(isIndividualSelected
            ? { expectedDepositVolume: productFormValues.getValue('expectedDepositVolume') }
            : {}),
          other: productFormValues.getValue('otherValue'),
          ...(campaign ? { campaign } : {})
        }
      }
      try {
        await signup(data).unwrap()
        history.push({
          pathname: '/check-email',
          state: {
            email: data?.email
          }
        })
      } catch (error) {
        formValues.handleErrors(error)
        setStep(1)
      }
    }
  }

  const validData = () =>
    formValues.getValue('countryCode') !== '' &&
    formValues.getValue('email') !== '' &&
    formValues.getValue('password') !== '' &&
    formValues.getValue('entityType') !== '' &&
    formValues.getValue('contactPhone') !== ''

  const handleSubmitClick = () => {
    if (productFormValues.areValid()) {
      if (isValidFunction(handleSignupSubmit)) {
        void handleSignupSubmit()
      }
    }
  }

  // useAnalytics({
  //   enabled: configuration.isProduction(),
  //   script: `
  //     twq('event', 'tw-op1sp-opj4f', {
  //       email_address: ${formValues.getValue('email') ? `'${formValues.getValue('email')}'` : 'null'},
  //       phone_number: ${formValues.getValue('contactPhone') ? `'${fixPhoneNumber(formValues.getValue('contactPhone'))}'` : 'null'}
  //     });
  //   `
  // })

  const replaceUrl = (text: string, search: string, replacement: string): string => {
    const list = text.split(search)
    return list.length > 1 ? list.join(`<a href="${replacement}" target='_blank' rel='noreferrer'>`) : text
  }

  const prepareAgreement = (): string => {
    const original = tr(
      'FRONTEND.SIGNUP.AGREEMENT',
      'I confirm that I have read and agree with <a data-type="terms-and-conditions">Terms and Conditions</a>, <a data-type="terms-of-use">Terms of Use</a> and <a data-type="privacy-policy">Privacy Policy</a>.'
    )
    return replaceUrl(
      replaceUrl(
        replaceUrl(
          original,
          '<a data-type="terms-and-conditions">',
          'https://www.thekingdombank.com/terms-and-conditions'
        ),
        '<a data-type="privacy-policy">',
        'https://www.thekingdombank.com/privacy-policy'
      ),
      '<a data-type="terms-of-use">',
      'https://www.thekingdombank.com/terms-of-use'
    )
  }

  return (
    <div className='register-form-wrapper'>
      <div className='register-form-title'>
        <h2>{tr('FRONTEND.SIGNUP.NEW_TITLE', 'Sign up')}</h2>
        <CoreSteps step={step} maxStep={2} title={isMobile ? null : stepTitle[step]} />
      </div>

      <div className='subtitle'>{stepSubtitle[step]}</div>

      {step === 1 && (
        <div>
          <form noValidate>
            <div className='form-inputs'>
              <div className='entityType-container'>
                <CustomCheckbox
                  icon={<CompanyIcon />}
                  caption={tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.COMPANY', 'Corporate Account')}
                  checked={formValues.getValue('entityType') === 'COMPANY'}
                  toggle={() => formValues.setValue('entityType', 'COMPANY')}
                  dataTest='account-type'
                />
                <CustomCheckbox
                  icon={<IndividualIcon />}
                  caption={tr('FRONTEND.SIGNUP.ACCOUNT_TYPES.INDIVIDUAL', 'Individual Account')}
                  checked={formValues.getValue('entityType') === 'INDIVIDUAL'}
                  toggle={() => formValues.setValue('entityType', 'INDIVIDUAL')}
                  dataTest='account-type'
                />
              </div>
              <CoreInput
                id='name'
                type='text'
                data-test='name'
                label={
                  formValues.getValue('entityType') === 'INDIVIDUAL'
                    ? 'Your name'
                    : formValues.getValue('entityType') === 'COMPANY'
                    ? 'Company Trading Name'
                    : 'Your name / Company trading name'
                }
                {...registerInput('name')}
                disabled={signUpFetching || !formValues.getValue('entityType').length}
                autoComplete='off'
                className='register-form-input-company-name'
              />
              <div className='register-form-inputs'>
                {countryOptions.length > 1 ? (
                  <CoreSelect
                    type='country'
                    name='countryCode'
                    data-test='country-code'
                    label={tr('FRONTEND.SIGNUP.COUNTRY_LABEL', 'Country')}
                    value={formValues.getValue('countryCode')}
                    onChange={handleCountryChange}
                    options={countryOptions}
                    disabled={signUpFetching || !formValues.getValue('entityType').length}
                  />
                ) : (
                  <CoreSelect
                    type='country'
                    data-test='country-code'
                    label={tr('FRONTEND.SIGNUP.COUNTRY_LABEL', 'Country')}
                    value={countryOptions?.find((c: any) => c?.value === formValues.getValue('countryCode'))?.label}
                    required={formValues.isRequired('countryCode')}
                    disabled={signUpFetching || !formValues.getValue('entityType').length}
                  />
                )}
                <CoreInput
                  id='phone'
                  type='phone'
                  data-test='contact-phone'
                  label={tr('FRONTEND.SIGNUP.PHONE', 'Contact phone')}
                  {...registerInput('contactPhone')}
                  disabled={signUpFetching || !formValues.getValue('entityType').length}
                  autoComplete='off'
                />
                <CoreInput
                  id='email'
                  type='email'
                  data-test='email'
                  label={tr('FRONTEND.SIGNUP.EMAIL', 'Email')}
                  {...registerInput('email')}
                  disabled={signUpFetching || !formValues.getValue('entityType').length}
                  autoComplete='off'
                />

                <CoreInput
                  id='password'
                  type='password'
                  data-test='password'
                  label={tr('FRONTEND.SIGNUP.PASSWORD', 'Password')}
                  {...registerInput('password')}
                  disabled={signUpFetching || !formValues.getValue('entityType').length}
                  autoComplete='new-password'
                  focused={passwordFocused}
                  setFocus={setPasswordFocused}
                />
                {passwordFocused && <PasswordTerms password={formValues.getValue('password')} />}
              </div>
            </div>
          </form>
        </div>
      )}

      {step === 2 && <ProductForm isIndividualSelected={isIndividualSelected} productFormValues={productFormValues} />}

      <div className='form-actions'>
        <ErrorDisplay error={[countriesError, signUpError]} />

        {step === 2 && (
          <div className='user-agreement'>
            <CoreCheckbox type='check' value='agreement' checked={agree} onClick={setAgree} />
            <div>{parse(prepareAgreement())}</div>
          </div>
        )}
        <CoreButton
          type='button'
          text={
            step === 1 ? tr('FRONTEND.BUTTONS.CONTINUE', 'Continue') : tr('FRONTEND.SIGNUP.BUTTON_CREATE', 'Sign up')
          }
          disabled={
            step === 1
              ? signUpFetching || !validData()
              : (!getValueProduct('bankingServices') &&
                  !getValueProduct('paymentServices') &&
                  !getValueProduct('forex') &&
                  !getValueProduct('other')) ||
                (isIndividualSelected && !getValueProduct('expectedDepositVolume')) ||
                !agree
          }
          isLoading={signUpFetching}
          onClick={step === 1 ? handleContinueClick : handleSubmitClick}
          data-test='button-submit'
          fullWidth
          size='large'
        />

        <div data-test='dont-have-account-test' className='auth-signup-link'>
          {tr('FRONTEND.LOGIN.YOU_HAVE_AN_ACCOUNT', 'Already have an account?')}
          <Link to={paths.login()} data-test='sign-up'>
            <span className='auth-signup-link-text'>
              {tr('FRONTEND.LOGIN.SIGNIN_BUTTON', 'Sign in')} <ArrowIcon />
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}
