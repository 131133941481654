import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useDebounce } from '@utils'
import { useMatchMedia } from '@hooks'
import { fixDateOnly, isValidArray, isValidString, tr, useFilter, usePagination } from 'mmfintech-commons'
import {
  selectCurrentUserRole,
  useAppSelector,
  useCreateReportMutation,
  useGetMerchantQuery,
  useGetReportListWithoutCacheMutation
} from 'mmfintech-backend-api'

import { ReportList } from '@views/expenseManagement'
import { ReportsSideFilter } from './ReportsSideFilter'
import { CoreButton, CoreInput, CoreLoader, CoreSidebarRight, ExpenseTabs } from '@components'

import { CustomerRole, CustomerRoleEnum, ReportStateEnum } from 'mmfintech-commons-types'

import FiltersIcon from '@images/icons/transactions-filter-icon.svg?react'
import SearchIcon from '@images/icons/search-icon.svg?react'

import './expenseDashboard.scss'

const fieldsToOmit = new Set(['owner', 'state'])

const isValidField = (key: string, value: any) => {
  const isDateValid = value instanceof Date && !isNaN(value.valueOf())
  return (isValidString(value) || isDateValid) && !fieldsToOmit.has(key)
}

export const ExpensesDashboard = () => {
  const { isFetching: merchantFetching } = useGetMerchantQuery(null)
  const customerRole = useAppSelector(selectCurrentUserRole)

  const tabConfig = getTabConfig(customerRole)
  const [tab, setTab] = useState(tabConfig?.[0]?.name)
  const [getReportsWithoutCache, { data, isLoading }] = useGetReportListWithoutCacheMutation()
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
  const [applyFilter, setApplyFilter] = useState<boolean>(false)
  const isCollapsedButtons = useMatchMedia({ breakpoint: 1600, prefix: 'max' })
  const outsideSearch = useMatchMedia({ breakpoint: 1480, prefix: 'min' })
  const isLarge = useMatchMedia({ breakpoint: 1380, prefix: 'min' })
  const [countFilters, setCountFilters] = useState<number>(0)
  const applySearch = useDebounce(() => {
    setApplyFilter(true)
  }, 300)

  const [_, { isSuccess: isCreateReportSuccess, reset: createReset }] = useCreateReportMutation({
    fixedCacheKey: 'refetch-reports'
  })

  const filterValues = useFilter({}, ['search'], ['fromDate', 'toDate'])

  const pagination = usePagination({
    rowsPerPage: 7,
    reload: async (params: any, onSuccess: (response: any) => void) => {
      try {
        const states = getState(tab)
        const response = await getReportsWithoutCache({
          ...params,
          ...filterValues.prepare(),
          ...(filterValues.get('from') ? { fromDate: fixDateOnly(filterValues.get('from')) } : null),
          ...(filterValues.get('to') ? { toDate: fixDateOnly(filterValues.get('to')) } : null),
          ...(isValidArray(states) ? { states } : null),
          deleted: false,
          hideDraftReports: tab === 'all-finance',
          owner: false
        }).unwrap()

        onSuccess(response)
      } catch (err) {
        toast.error(err?.cause?.[0]?.cause || err.error)
      }
    }
  })

  useEffect(() => {
    if (isCreateReportSuccess) {
      setApplyFilter(true)
      createReset()
    }
  }, [isCreateReportSuccess])

  useEffect(() => {
    if (applyFilter) {
      setCountFilters(
        Object.entries(filterValues.filterValues).reduce((count, [key, value]) => {
          return count + (isValidField(key, value) ? 1 : 0)
        }, 0)
      )

      pagination.reload()
      setApplyFilter(false)
    }
  }, [applyFilter])

  useEffect(() => {
    if (outsideSearch) {
      applySearch()
      return applySearch()
    }
  }, [filterValues.get('search')])

  const handleTabChange = (selectedTab: string) => {
    if (selectedTab !== tab) {
      setTab(selectedTab)
      // filterValues.set('states', getState(selectedTab))
      filterValues.set('owner', selectedTab === 'your-reports')
      setApplyFilter(true)
    }
  }

  const clearAll = () => {
    filterValues.reset(['counterpartyId', 'foreignId', 'states'] as any)
    setApplyFilter(true)
  }

  const isApprover = [
    'awaiting-approval',
    'awaiting-finance-approval',
    'awaiting-payment',
    'paid',
    'all-finance'
  ].includes(tab)

  return (
    <div className='reports-wrapper'>
      <div className='table-wrapper'>
        <div className='reports-header'>
          <ExpenseTabs config={tabConfig} currentTab={tab} setTab={handleTabChange} />
          <div className='reports-header-filters'>
            <CoreInput
              type='text'
              name='search'
              {...filterValues.registerInput('search')}
              label={tr('FRONTEND.EXPENSES.FILTER.NAME_DESCRIPTION', 'Name, Description')}
              LeftIcon={<SearchIcon />}
              data-test='filter-search'
            />
            <CoreButton
              onClick={() => setOpenFilterModal(true)}
              variation='tertiary'
              size='large'
              className='transaction-filter-button'
              text={tr('FRONTEND.TRANSACTIONS.FILTER.FILTERS_BUTTON', 'Filters')}
              LeftIcon={<FiltersIcon />}
              CollapsedIcon={<FiltersIcon />}
              tooltip={isCollapsedButtons ? tr('FRONTEND.TRANSACTIONS.FILTER.FILTERS_BUTTON', 'Filters') : ''}
              collapsed={isCollapsedButtons && isLarge}
              RightIcon={countFilters > 0 && <span className='transaction-filter-count-icon'>{countFilters}</span>}
            />
          </div>
        </div>

        {isLoading || merchantFetching ? (
          <CoreLoader />
        ) : (
          <ReportList data={data?.content || []} pagination={pagination} isApprover={isApprover} />
        )}
      </div>
      <CoreSidebarRight
        content={
          <ReportsSideFilter
            filters={filterValues}
            setApplyFilter={setApplyFilter}
            modalHide={() => setOpenFilterModal(false)}
            clearAll={clearAll}
            countFilters={countFilters}
          />
        }
        visible={openFilterModal}
        options={{ size: 'extra-large' }}
        header={tr('FRONTEND.TRANSACTIONS.FILTER.TITLE', 'Filters')}
        onClose={() => setOpenFilterModal(false)}
      />
    </div>
  )
}

const getState = (tab: string) => {
  switch (tab) {
    case 'awaiting-approval':
      return [ReportStateEnum.WAITING_APPROVAL]
    case 'awaiting-finance-approval':
      return [ReportStateEnum.WAITING_FINANCE_APPROVAL]
    case 'awaiting-payment':
      return [ReportStateEnum.APPROVED]
    case 'paid':
      return [ReportStateEnum.COMPLETELY_PAID, ReportStateEnum.PARTIALLY_PAID, ReportStateEnum.UNPAID]
    case 'draft':
      return [ReportStateEnum.NEW]
    case 'pending':
      return [ReportStateEnum.WAITING_APPROVAL, ReportStateEnum.WAITING_FINANCE_APPROVAL, ReportStateEnum.APPROVED]
    default:
      return []
  }
}

const getTabConfig = (role: CustomerRole) => {
  switch (role) {
    case CustomerRoleEnum.SUBMITTER:
      return [
        {
          title: tr('FRONTEND.REPORTS.TABS.ALL_REPORTS', 'All Reports'),
          name: 'all'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.PENDING_REPORTS', 'Pending Reports'),
          name: 'pending'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.DRAFT_REPORTS', 'Draft Reports'),
          name: 'draft'
        }
      ]
    case CustomerRoleEnum.APPROVER:
      return [
        {
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_YOUR_APPROVAL', 'Awaiting your approval'),
          name: 'awaiting-approval'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.YOUR_REPORTS', 'Your reports'),
          name: 'your-reports'
        }
      ]
    case CustomerRoleEnum.OWNER:
    case CustomerRoleEnum.ADMINISTRATOR:
      return [
        {
          title: tr('FRONTEND.REPORTS.TABS.ALL_REPORTS', 'All Reports'),
          name: 'all-finance'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_YOUR_APPROVAL', 'Awaiting your approval'),
          name: 'awaiting-finance-approval'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.AWAITING_PAYMENT', 'Awaiting payment'),
          name: 'awaiting-payment'
        },
        {
          title: tr('FRONTEND.REPORTS.TABS.CONCLUDED', 'Concluded'),
          name: 'paid'
        }
      ]
    default:
      return []
  }
}
