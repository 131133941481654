import { useContext } from 'react'

import { useChangeTwoFactorMutation } from 'mmfintech-backend-api'
import { GlobalContext, tr } from 'mmfintech-commons'
import { TwoFactorType } from 'mmfintech-commons-types'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { CoreButton } from '@components'

import '../styled/disableSmartIdModal.scss'

export const DisableSmartIdModal = ({ new2FA }: { new2FA: TwoFactorType }) => {
  const { modalHide } = useContext(GlobalContext)
  const [change2fa, { error: change2faError }] = useChangeTwoFactorMutation()

  return (
    <div className='disable-smart-id-modal-container'>
      <div className='info-text'>
        <p>
          {tr(
            'FRONTEND.SECURITY.TWO_FACTOR.SMART_ID_DISABLE_INFO',
            'If you disable SmartID or change to another authentication method, you will be able to enable it again from the mobile app. '
          )}
        </p>
      </div>

      <ErrorDisplay error={change2faError} />
      <div className='button-container'>
        <CoreButton
          text={tr('FRONTEND.SECURITY.TWO_FACTOR.SMART_ID_DISABLE_BUTTON', 'Disable')}
          onClick={() => {
            change2fa({ twoFactor: new2FA })
          }}
          fullWidth
          size='large'
        />
        <CoreButton
          text={tr('FRONTEND.SECURITY.TWO_FACTOR.SMART_ID_DISABLE_CANCEL_BUTTON', 'Cancel')}
          onClick={modalHide}
          size='large'
          variation='tertiary'
        />
      </div>
    </div>
  )
}
