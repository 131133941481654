import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import './HamburgerMenu.scss'
import cn from 'classnames'

import { tr } from 'mmfintech-commons'
import {
  api,
  paths,
  useAuth,
  useGetMerchantQuery,
  useGetProfileQuery,
  useLazyCardLogoutQuery,
  useLazyLogoutQuery
} from 'mmfintech-backend-api'

import { CustomerRoleEnum, LoginStatusEnum } from 'mmfintech-commons-types'
import { coreMenuItems } from '../../constants/coreMenuItems'

import KingdomBankLogo from '@images/kingdom-bank-icon-black.svg?react'

import SettingsIcon from '@images/icons/settings-icon.svg?react'
import LogoutIcon from '@images/icons/logout-icon.svg?react'
import UserIcon from '@images/icons/user-icon.svg?react'
import DeveloperIcon from '@images/icons/developers-icon.svg?react'

export const HamburgerMenu = ({
  opened,
  maxWidth,
  onClose
}: {
  opened: boolean
  maxWidth?: string
  onClose: () => void
}) => {
  const userStatus = useAuth()
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN
  const [logout] = useLazyLogoutQuery()
  const [cardLogout] = useLazyCardLogoutQuery()
  const { data: merchant } = useGetMerchantQuery()
  const { capabilities, apiEnabled } = (merchant as any) || {}
  const { allowReferrals, forexAccountRequestStatus } = capabilities || {}
  const isForexEnabled = () => forexAccountRequestStatus !== 'NOT_REQUESTED' && isOwnerOrAdministrator()
  const { data: profile } = useGetProfileQuery(null)
  const { customerRole, isCardIssuingEnabled } = (profile as any) || {}
  const isOwnerOrAdministrator = () =>
    customerRole === CustomerRoleEnum.OWNER || customerRole === CustomerRoleEnum.ADMINISTRATOR
  const isDevelopersMenuVisible = () => !!apiEnabled && isOwnerOrAdministrator()

  const handleLogoutClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    await cardLogout(null).unwrap()
    await logout(null).unwrap()
    dispatch(api.util.resetApiState())
    return false
  }

  return (
    <div className={cn('hamburger-menu-wrapper', { open: opened, maxMobileWidth: maxWidth })}>
      <div className='hamburger-menu-content'>
        {/* <CloseIcon onClick={hideMenu} /> */}
        <div className='hamburger-menu-header'>
          <KingdomBankLogo />
        </div>
        {isLoggedIn() && coreMenuItems(customerRole, isCardIssuingEnabled, merchant) && (
          <div className='menu-items-container'>
            {coreMenuItems(customerRole, isCardIssuingEnabled, merchant).map(
              ({ Image, label, path, localization }, index) => {
                return (
                  <div
                    className='item'
                    key={label + index}
                    onClick={() => {
                      onClose()
                      history.push(path)
                    }}
                    style={{
                      display:
                        (label === 'Invite & Earn' && !allowReferrals) || (label === 'Forex' && !isForexEnabled())
                          ? 'none'
                          : ''
                    }}>
                    <Image />
                    <span>{tr(localization, label)}</span>
                  </div>
                )
              }
            )}
          </div>
        )}
        {isLoggedIn() && (
          <div className='menu-footer'>
            {isOwnerOrAdministrator() && (
              <div
                onClick={e => {
                  onClose()
                  e.preventDefault()
                  history.push(paths.userManagement())
                }}
                className='item'>
                <UserIcon />
                <span>User management</span>
              </div>
            )}

            {isDevelopersMenuVisible() && (
              <div
                className='item'
                onClick={e => {
                  onClose()
                  e.preventDefault()
                  history.push(paths.developer())
                }}>
                <DeveloperIcon />
                <span>Developers</span>
              </div>
            )}
            <div
              className='item'
              onClick={e => {
                onClose()
                e.preventDefault()
                history.push(paths.profile())
              }}>
              <SettingsIcon />
              <span>Profile settings</span>
            </div>
            <div
              onClick={e => {
                handleLogoutClick(e)
                onClose()
              }}
              className='item logout'>
              <LogoutIcon />
              <span>Log out</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
