import { Link } from 'react-router-dom'

import GooglePlayIcon from '@images/icons/googleplay-app-icon.svg?react'
import settings from '@settings'

import './downloadAppButtons.scss'

export const GooglePlayButton = () => {
  return (
    <div className='download-app-link'>
      <Link to={{ pathname: settings.googlePlayUrl }} target='_blank' data-test='download-app-googlePlay'>
        <GooglePlayIcon data-test='download-app-googleplay-icon' />
      </Link>
    </div>
  )
}
