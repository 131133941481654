import { useEffect, useState } from 'react'

import settings from '@settings'
import QRCode from 'qrcode.react'

import { tr } from 'mmfintech-commons'

import '../styled/enableSmartIdModal.scss'
import { getOS } from '@utils'
import { AppStoreButton, CoreTabs, GooglePlayButton } from '@components'

export const EnableSmartIdModal = () => {
  const [selectedTab, setSelectedTab] = useState('AppStore')

  useEffect(() => {
    const os = getOS()
    if (os === 'Mac') {
      setSelectedTab('GooglePlay')
    }
  }, [])

  return (
    <div className='enable-smart-id-modal-container'>
      <div className='info-text'>
        <p>
          {tr('FRONTEND.SECURITY.TWO_FACTOR.SMART_ID_ENABLE_INFO', 'SmartID can be enabled through the mobile app. ')}
        </p>
        <p>
          {tr(
            'FRONTEND.SECURITY.TWO_FACTOR.SMART_ID_ENABLE_INFO_2',
            'Install and log in into the mobile app on the device you would like to receive secure push notifications and enable SmartID from the app settings.'
          )}
        </p>
      </div>

      <CoreTabs
        onChangeTab={setSelectedTab}
        tabs={[
          {
            label: tr('FRONTEND.SECURITY.TWO_FACTOR.SMART_ID_ENABLE_INFO_3', 'App Store'),
            value: 'AppStore'
          },

          {
            label: tr('FRONTEND.SECURITY.TWO_FACTOR.SMART_ID_ENABLE_INFO_4', 'Google Play'),
            value: 'GooglePlay'
          }
        ]}
        tabState={selectedTab}
      />
      <div>
        <div className='qr-code-container'>
          <QRCode
            value={selectedTab === 'AppStore' ? settings.appStoreUrl : settings.googlePlayUrl}
            renderAs='svg'
            size={150}
          />
        </div>
        <div className='qr-code-text'>
          <p>
            {tr(
              'FRONTEND.SECURITY.TWO_FACTOR.SMART_ID_ENABLE_INFO_5',
              'Scan the QR code to download the app or click the button below.'
            )}
          </p>
        </div>
      </div>

      {selectedTab === 'AppStore' ? <AppStoreButton /> : <GooglePlayButton />}
    </div>
  )
}
