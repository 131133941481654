import { configuration, initialize } from 'mmfintech-backend-api'

initialize(import.meta.env)

const settings = {
  backendForLocalhost: 'https://portal-dev.thekingdombank.com',
  // backendForLocalhost: 'https://portal-sandbox.thekingdombank.com',

  cookieDomain: configuration.isLocal() ? null : '.thekingdombank.com',
  customerServiceUrl: 'mailto:team@thekingdombank.com',
  privacyPolicyUrl: 'https://thekingdombank.com/privacy-policy',
  termsAndConditionsUrl: 'https://thekingdombank.com/terms-and-conditions',
  FAQUrl: 'https://thekingdombankhelp.zendesk.com/theming/theme/050fff4c-3294-41e4-95d2-9d801855a5ab',
  landingPageUrl: configuration.isDevelopment()
    ? 'https://dev.thekingdombank.com'
    : configuration.isSandbox()
    ? 'https://sandbox.thekingdombank.com'
    : 'https://thekingdombank.com/',
  languages: ['en'],

  logoAcceptType: ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  logoMaxFileSize: 1024 * 1024,
  questionnaireFileTypes: ['pdf', 'jpg', 'jpeg', 'png'],
  questionnaireMaxFileSize: 1024 * 1024 * 10,
  defaultUploadInvoiceAcceptType: ['pdf', 'jpg', 'jpeg', 'png'],
  defaultUploadInvoiceMaxFileSize: 1024 * 1024,
  // TODO: change to the correct urls
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.thekingdombank.app',
  appStoreUrl: 'https://apps.apple.com/app/id6469883222',

  //Voucher
  // TODO: expose endpoint to get this config
  minVoucherAmount: 5,
  maxVoucherAmount: 5000
}

export default settings
