import { Link } from 'react-router-dom'

import AppstoreIcon from '@images/icons/appstore-app-icon.svg?react'
import settings from '@settings'

import './downloadAppButtons.scss'

export const AppStoreButton = () => {
  return (
    <div className='download-app-link'>
      <Link to={{ pathname: settings.appStoreUrl }} target='_blank' data-test='download-app-appstore'>
        <AppstoreIcon data-test='download-app-appstore-icon' />
      </Link>
    </div>
  )
}
